import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { useQueryParam, getSearchParams } from "gatsby-query-params";
import { MasterPage, QuotesPanel } from "../../components";
import SEO from "../../components/seo"



const Quotes = () => {
  const data = useStaticQuery(graphql`query siteData {
    site {
      siteMetadata {
        agent {
            assets {
                image
            },
            name,
            phone,
            whatsapp,
            email,
            website,
            title,
            imageSrc
        }
      }
    }
  }
`)


  return (<MasterPage data={data}>
    <SEO title="Quotes Panel" />
    <QuotesPanel {...getSearchParams()} data={data}/>
  </MasterPage>
)}

export default Quotes
